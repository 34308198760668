.paper {
  padding: 12px;
}

.formRow {
  margin-bottom: 20px;
}

.formField {
  width: 100%;
}

.flagSelector {
  width: 100%;
}
