.container {
  display: flex;
}

.container.reverse {
  flex-direction: row-reverse;
}

.summary {
  position: relative;
  z-index: 1;
}
