.root {
  flex-grow: 1;
}

.paper {
  padding: 12px;
}

.swapButton {
  display: block;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.playerSettings > div {
  margin-bottom: 20px;
}
