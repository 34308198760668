.container {
  position: relative;
  width: 2rem;
  height: 2rem;
  box-shadow: 0 2px 8px rgba(0 0 0 / 60%);
}

.levelBar {
  position: absolute;
  bottom: -5%;
  left: 0;
  right: 0;
}
