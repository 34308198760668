.container {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.container.compact {
  flex-direction: row;
  margin-left: 0.5rem;
}

.container.reverse {
  align-items: flex-end;
}

.container.compact.reverse {
  flex-direction: row-reverse;
  margin-left: 0rem;
  margin-right: 0.5rem;
}

.item {
  margin-top: 0.5rem;
  box-shadow: 0 4px 16px rgba(0 0 0 / 80%);
}

.item.first {
  margin-top: 0rem;
}

.compact .item {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.compact:not(.reverse) .item {
  margin-right: 0.5rem;
}

.compact.reverse .item {
  margin-left: 0.5rem;
}
