.container {
  display: grid;
  grid-template: 2.5rem / repeat(4, 2.5rem);
  gap: 0.125rem;
}

.slot {
  width: 100%;
  height: 100%;
  background: var(--white-10);
  border-radius: var(--border-radius);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}
