.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.content {
  position: relative;
  top: 8vw;
  width: 50%;
  margin: 0 auto;
  background: var(--bg-translucent);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2rem 4rem rgba(0 0 0 / 80%);
}
