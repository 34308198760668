.container {
  --bg-color: var(--gray-0);
  --border-size: 0.25rem;
  --angle: 28.5deg;
  --border-color: var(--team-color);

  position: relative;
  display: flex;
}

.container.reverse {
  flex-direction: row-reverse;
}

.backgroundImage {
  width: 100%;
  height: auto;
  color: var(--border-color);
}

.reverse .backgroundImage {
  transform: scaleX(-1);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 4.375%;
}

.reverse .content {
  padding-right: 0;
  padding-left: 4.375%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.25rem;
  height: 51.22%;
}

.reverse .top {
  flex-direction: row-reverse;
  padding-right: 0;
  padding-left: 0.25rem;
}

.bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 48.78%;
}

.reverse .bottom {
  flex-direction: row-reverse;
}

.left {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 32.5%;
  height: 79%;
  margin-right: 2%;
}

.reverse .left {
  flex-direction: row-reverse;
  margin-right: 0;
  margin-left: 2%;
}

.resources {
  flex-grow: 1;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}

.reverse .resources {
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}

.upgrades {
  flex-grow: 1;
  flex-wrap: wrap;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.25rem;
  max-height: 2rem;
}

.upgrade {
  margin-left: 0.125rem;
  margin-bottom: 0.125rem;
}

.upgrade:first-child {
  margin-left: 0;
}

.reverse .upgrade {
  margin-left: 0;
  margin-right: 0.125rem;
}

.reverse .upgrade:first-child {
  margin-left: 0;
  margin-right: 0;
}

.reverse .upgrades {
  flex-direction: row-reverse;
}

.right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3%;
  height: 90%;
  margin-left: 2.5%;
}

.reverse .right {
  margin-left: 0;
  margin-right: 2.5%;
}

.flag {
  width: 1.5rem;
}

.score {
  color: var(--gray-5);
  font-size: 1.5rem;
  text-align: center;
}

.player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12.5rem;
  padding: 0.25rem 0.5rem;
}

.reverse .player {
  flex-direction: row-reverse;
}

.apm {
  color: var(--text-color);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1em;
  text-align: center;
}

.apm small {
  color: var(--gray-4);
  font-size: 0.625rem;
}

.playerName {
  flex-grow: 1;
  text-align: center;
  color: var(--gray-5);
  font-size: 1.125rem;
  line-height: 1em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playerName > span {
  max-width: 13ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse .playerName {
  flex-direction: row-reverse;
}

.playerName > img {
  height: 1em;
  margin-left: 0.5rem;
}

.reverse .playerName > img {
  margin-right: 0.5rem;
  margin-left: 0;
}

.techLevel {
  color: var(--gray-5);
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  margin: 0.25rem 0;
  border-left: 1px solid var(--gray-2);
}

.reverse .techLevel {
  border-left: none;
  border-right: 1px solid var(--gray-2);
}
