.container {
  min-width: 4rem;
  background: var(--black-60);
  color: var(--text-color);
  font-size: 1.125rem;
  line-height: 1em;
  font-weight: 700;
  text-align: center;
  padding: 0.125rem;
}
