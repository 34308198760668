.fallback {
  color: var(--color);
  background: var(--bg-translucent);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1rem;
  width: 100%;
  height: 100%;
}
