.container {
  --height: 2.5rem;

  position: relative;
  background: var(--gray-1);
  display: flex;
  justify-content: center;
  width: fit-content;
}

.container.reverse {
  flex-direction: row-reverse;
}

.unitCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
  min-width: 1.5rem;

  color: var(--text-color);
  font-weight: 700;
  font-size: 0.75rem;
}

.unitCount > svg {
  width: 1rem;
  height: 1rem;
  color: var(--gray-4);
}

.raceEmblem {
  display: block;
  width: var(--height);
  height: var(--height);
}
