html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: rgba(0, 0, 0, 1);
  --bg-translucent: rgba(0, 0, 0, 0.8);
  --bg-light-translucent: rgba(255 255 255 / 10%);
  --color: #ffffff;
  --border-radius: 0.125rem;
  --padding: 8px;
  --experience-color: #eec12d;
  --health-color: #50d344;
  --mana-color: #1f80db;

  --white-10: rgba(255, 255, 255, 0.1);
  --white-20: rgba(255, 255, 255, 0.2);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-60: rgba(0, 0, 0, 0.6);
  --black: #000000;

  --gray-0: #111111;
  --gray-1: #333333;
  --gray-2: #4f4f4f;
  --gray-3: #828282;
  --gray-4: #bdbdbd;
  --gray-5: #e0e0e0;

  --yellow: #f2c94c;
  --text-color: #f2f2f2;
  --team-color: #ffffff;

  --Red: #ff0303;
  --Blue: #0042ff;
  --Teal: #1ce6b9;
  --Purple: #540081;
  --Yellow: #fffc00;
  --Orange: #fe8a0e;
  --Green: #20c000;
  --Pink: #e55bb0;
  --Gray: #959697;
  --LightBlue: #7ebff1;
  --DarkGreen: #106246;
  --Brown: #4e2a04;
  --Maroon: #9b0000;
  --Navy: #0000c3;
  --Turqoise: #00eaff;
  --Violet: #be00fe;
  --Wheat: #ebcd87;
  --Peach: #f8a48b;
  --Mint: #bfff80;
  --Lavender: #dcb9eb;
  --Coal: #282828;
  --Snow: #ebf0ff;
  --Emerald: #00781e;
  --Peanut: #a46f33;
}
