.container {
  height: 100%;
  display: grid;
  grid-template: auto 1fr 1fr / auto;
  gap: 0.25rem;
}

.container.compact {
  gap: 0.125rem;
}

.cameoContainer {
  position: relative;
  min-width: 4rem;
  min-height: 4rem;
}

.compact .cameoContainer {
  --width: 3rem;
  min-width: var(--width);
  min-height: var(--width);
  width: var(--width);
  height: var(--width);
}

.level {
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 1.5em;
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--text-color);
  background: var(--black-60);
}

.grayscale {
  filter: grayscale(100%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 200ms ease-out;
  opacity: 1;
}

.overlay.hide {
  opacity: 0;
}

.cooldown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clock {
  color: var(--black-60);
}

.cooldown > .countdown {
  display: inline-block;
  position: relative;
  color: var(--text-color);
  font-size: 1.5rem;
  text-shadow: 0px 0px 4px #000000;
}

.healthBar {
  --progress-color: var(--health-color);
}

.manaBar {
  --progress-color: var(--mana-color);
}

.progressBackground {
  background: var(--white-20);
  min-height: 0.75rem;
}

.compact .progressBackground {
  min-height: 0.5rem;
}
