.container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-shadow: 0 4px 8px rgba(0 0 0 / 80%);
}

.progress {
  padding: 0.0625rem;
  background: var(--gray-1);
}

.progressBar {
  --progress-color: var(--team-color);
  box-shadow: 0px 0px 8px -1px var(--team-color);
}

.progressBackground {
  height: 0.25rem;
  background: var(--black-60);
}
