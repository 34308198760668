.container {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
}

.countContainer {
  --height: 0.75rem;

  position: absolute;
  bottom: -8%;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.count {
  position: relative;
  min-width: 2ch;
  text-align: center;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: var(--height);
  color: var(--text-color);
  background: var(--gray-1);
}

.count::before {
  content: "";
  position: absolute;
  left: -0.25rem;
  border-top: calc(var(--height) / 2) solid transparent;
  border-bottom: calc(var(--height) / 2) solid transparent;
  border-right: 0.25rem solid var(--gray-1);
}

.count::after {
  content: "";
  position: absolute;
  right: -0.25rem;
  border-top: calc(var(--height) / 2) solid transparent;
  border-bottom: calc(var(--height) / 2) solid transparent;
  border-left: 0.25rem solid var(--gray-1);
}
