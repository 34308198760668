.container {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  padding: 0.25rem;
}

.container.reverse {
  flex-direction: row-reverse;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.125rem;
}

.resourceContainer {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.resourceContainer span {
  min-width: 4ch;
}
