.container {
  display: flex;
  width: 100%;
  background: var(--black-60);
  overflow: hidden;
  justify-content: flex-start;
  box-shadow: 0 2px 8px rgba(0 0 0 / 60%);
}

.rightColumn {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  padding: 0.125rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.label {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 1rem;
  line-height: 1em;
  color: var(--text-color);
  padding: 0.25em 0;
}

.progressBar {
  --progress-color: var(--team-color);
  box-shadow: 0px 0px 8px -1px var(--team-color);
}

.progressBackground {
  height: 1rem;
  background: var(--white-20);
}
