.container {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.leftSide,
.rightSide {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.heroes {
  position: relative;
}

.heroes.compact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75%;
}

.rightSide .heroes.compact {
  justify-items: flex-end;
}

.rightSide .heroes {
  align-self: flex-end;
  align-items: flex-end;
}

.researchAndProduction {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 12rem;
}

.leftSide .researchAndProduction {
  align-self: flex-start;
}

.rightSide .researchAndProduction {
  align-self: flex-end;
}

.production {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.0625rem;
}

.reverse .production {
  flex-direction: row-reverse;
}

.gameTimeContainer {
  position: absolute;
  top: 4.225vw;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphContainer {
  position: relative;
  width: 50%;
  height: 50%;
  background: var(--black-60);
}

.ingameHeroCover {
  position: absolute;
  top: -0.5rem;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 10%;
  height: 50%;
  z-index: -1;
}
