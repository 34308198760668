.grid {
  --cell-size: 2rem;
  display: grid;
  grid-template: repeat(3, var(--cell-size)) / repeat(2, var(--cell-size));
  width: fit-content;
  justify-self: center;
}

.slot {
  position: relative;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backpackIcon {
  width: 1.5rem;
  height: 1.5rem;
}
