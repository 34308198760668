.container {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
}

.cooldownOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cooldown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--black-60);
}

.countdown {
  position: relative;
  color: var(--text-color);
  font-size: 0.875rem;
  font-weight: 500;
  text-shadow: 0px 0px 4px #000000;
}

.levelBar {
  position: absolute;
  bottom: -5%;
  left: 0;
  right: 0;
}
