.container {
  --grid-gap: 0.5rem;
  --border-width: 0.125rem;

  background: var(--black-60);
  display: grid;
  grid-template: auto auto / auto;
  gap: var(--grid-gap);
  width: fit-content;
  padding: 0.25rem;
  border-radius: calc(var(--border-radius) * 2);
}

.container.border {
  border-radius: 0 calc(var(--border-radius) * 2) calc(var(--border-radius) * 2)
    0;
  border-left: var(--border-width) solid var(--team-color);
}

.container.compact {
  --grid-gap: 0.25rem;
  grid-template: auto / auto;
}

.container.border.reverse {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  border-left: none;
  border-right: var(--border-width) solid var(--team-color);
}

.topRow {
  display: grid;
  gap: var(--grid-gap);
  grid-template: "portrait . inventory" auto / auto 1fr auto;
  align-items: stretch;
}

.compact .topRow {
  grid-template: "portrait ." auto / auto auto;
}

.portrait {
  grid-area: portrait;
}

.inventory {
  grid-area: inventory;
}

.reverse .topRow {
  grid-template-areas: "inventory . portrait";
}

.reverse.compact .topRow {
  grid-template-areas: "portrait .";
}

.level {
  position: relative;
  display: grid;
  grid-template: auto 1fr / auto;
  justify-items: center;
  gap: 0.25rem;
}

.level > .label {
  position: relative;
  color: var(--text-color);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 1);
}

.level > .label > small {
  display: block;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1em;
}

.progressBackground {
  background: var(--white-20);
  max-width: 1rem;
}

.experienceBar {
  --progress-color: var(--experience-color);
}
