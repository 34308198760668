.container {
  position: relative;
}

.topLabel,
.bottomLabel {
  position: absolute;
  left: 1rem;
  color: var(--text-color);
  font-weight: 700;
  text-shadow: 0 0 0.25rem rgba(0 0 0 / 80%);
  padding: 0.125rem 0.25rem;
}

.topLabel > span,
.bottomLabel > span {
  position: relative;
  z-index: 1;
}

.topLabel::before,
.bottomLabel::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  background: var(--team-color);
  border-radius: var(--border-radius);
  height: 100%;
  width: 100%;
  opacity: 0.56;
  z-index: 0;
}

.topLabel {
  top: 25%;
}

.bottomLabel {
  bottom: 25%;
}
