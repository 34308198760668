.container {
  --progress-color: #ffffff;
  position: relative;
  height: 100%;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
}

.progressForeground {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: var(--progress-color);
  transition: all 250ms ease-in-out;
  border-radius: var(--border-radius);
}
